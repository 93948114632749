import { NavigationBar } from "../components/NavigationBar";
import memeImage from "../assets/meme.jpg";
import musicImage from "../assets/music.png";
import gaitImage from "../assets/gait-2.png";
import RobotImage from "../assets/robot.png";
import MysteriesImage from "../assets/mysteries.jpg";
import SelfOperatingComputerImage from "../assets/soc.png";
import { ContentContainer } from "../styles/pageStyles";
import {
  BigTileImages,
  PageContainer,
  MainContainer,
} from "../styles/pageStyles";
import { useEffect } from "react";
export const Projects = ({ isMobile }) => {
  useEffect(() => {
    document.title = "Projects";
  }, []); // Empty dependency array ensures this runs only once when the component mounts

  return (
    <PageContainer>
      <NavigationBar active={"Projects"} isMobile={isMobile} />

      <MainContainer style={{ maxWidth: "700px" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            margin: "50px 0px",
          }}
        >
          <ContentContainer>
            <div>
              <h3>
                <a href="https://www.willpayforthis.com/">WillPayForThis.com</a>
              </h3>
              <p>A shortcut to market-validated ideas.</p>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div>
              <h3>
                <a href="https://github.com/OthersideAI/self-operating-computer">
                  The Self-Operating Computer Framework
                </a>
              </h3>
              <p>
                In Nov. 2023 I built the first Computer Use agent. It is a
                framework to enable multimodal models to operate computers
                similar to how humans do. VentureBeat publish an{" "}
                <a href="https://venturebeat.com/ai/the-self-operating-computer-emerges/">
                  article
                </a>{" "}
                about it. There are a few demos available on{" "}
                <a href="https://www.youtube.com/watch?v=UKRti40U8IA&t=6s">
                  YouTube
                </a>
              </p>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div>
              <h3>
                <a href="https://makememe.ai/">makememe.ai</a>
              </h3>
              <h5 style={{ margin: 0, padding: 0 }}>
                <a href="https://www.producthunt.com/products/makememe-ai">
                  Product of the Day (Product Hunt)
                </a>
              </h5>
              <p>
                This app is a text-to-meme generator that uses GPT-3 and
                semantic search to create a meme based on user input in under 5
                seconds.{" "}
                <a href="https://github.com/joshbickett/makememe_ai">
                  The code
                </a>{" "}
                is available on Github. I published{" "}
                <a href="https://towardsdatascience.com/how-to-make-memes-with-ai-in-python-986944bce5b4">
                  How to Make Meme with AI in Python
                </a>{" "}
                in Toward Data Science to help understand how it works under the
                hood.
              </p>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div>
              <h3>
                <a href="https://www.mysteries.ai/">Mysteries.ai</a>
              </h3>
              <p>
                A mystery game where you play detective and chat with AI
                characters to solve the mystery
              </p>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div>
              <h3>
                <a href="http://whichisai.com/">WhichIsAI</a>
              </h3>
              <p>
                A simple game where the users tries to find which image is
                generated by AI
              </p>
            </div>
          </ContentContainer>
          <ContentContainer>
            <div>
              <h3>
                <a href="https://apps.apple.com/us/app/gait-a-driver-score-app/id1516971190">
                  Gait
                </a>
              </h3>
              <p>
                My cofounder and I started an insurance technology company
                called <a href="https://gaitcar.com/">Gait, Inc.</a> that
                offered a software development kit (SDK) to insurance companies
                to enable their usage-based insurance products. We sold the
                company through{" "}
                <a href="https://microacquire.com/">MicroAcquire</a>.
              </p>
            </div>
          </ContentContainer>
        </div>
      </MainContainer>
    </PageContainer>
  );
};
